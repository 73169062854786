export const menu = [
  {
    title: 'Inicio',
    path: '/',
  },
  {
    title: 'Miembros',
    path: '/miembros',
  },
  {
    title: 'Reseñas',
    path: '/posts',
  },
  {
    title: 'Escritura creativa',
    path: '/escritura-creativa',
  },
  {
    title: 'Contacto',
    path: '/contacto',
  },
];
