exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-categorias-escritura-creativa-index-tsx": () => import("./../../../src/pages/categorias/escritura-creativa/index.tsx" /* webpackChunkName: "component---src-pages-categorias-escritura-creativa-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-author-list-js": () => import("./../../../src/templates/author-list.js" /* webpackChunkName: "component---src-templates-author-list-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-category-list-js": () => import("./../../../src/templates/category-list.js" /* webpackChunkName: "component---src-templates-category-list-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-poem-list-js": () => import("./../../../src/templates/poem-list.js" /* webpackChunkName: "component---src-templates-poem-list-js" */),
  "component---src-templates-single-author-js": () => import("./../../../src/templates/single-author.js" /* webpackChunkName: "component---src-templates-single-author-js" */),
  "component---src-templates-single-blog-js": () => import("./../../../src/templates/single-blog.js" /* webpackChunkName: "component---src-templates-single-blog-js" */),
  "component---src-templates-single-category-js": () => import("./../../../src/templates/single-category.js" /* webpackChunkName: "component---src-templates-single-category-js" */),
  "component---src-templates-single-poem-js": () => import("./../../../src/templates/single-poem.js" /* webpackChunkName: "component---src-templates-single-poem-js" */)
}

